export const English = {
    dashboard: 'Dashboard',
    admins: "Admins",
    admin: "Admin",
    rules: "Rules",
    users: "Users",
    categories: "Categories",
    category: "Category",
    sub_categories: "SubCategories",
    sub_category: "SubCategory",
    brands: "Brands",
    variant: "Variant",
    dynamic_variant: 'Dynamic Variant',
    products: 'Products',
    product: 'Product',
    digital_products:'Digital Products',
    orders: 'Orders',
    order: 'Order',
    reports: 'Reports',
    notification: 'Notification',
    promo_codes: 'Promo Codes',
    promo_code: 'Promo Codes',
    time_slot: 'Time Slot',
    banners: 'Banners',
    ad_screen: 'Ad Screen',
    control: 'Control',
    setting: 'Setting',
    delivery: 'Delivery',
    social_media: 'Social Media',
    currency: 'Currency',
    payment: 'Payment',
    pages: 'Pages',
    total_users: 'Total Users',
    total_categories: 'Total Categories',
    total_sub_categories: 'Total Sub Categories',
    total_brands: 'Total Brands',
    total_admins: 'Total Admins',
    total_orders: 'Total Orders',
    on_the_way: 'On the Way',
    canceled: 'Canceled',
    delivered: 'Delivered',
    shipped: "Shipped",
    process: "Process",
    ordered: "Ordered",
    total_sales: 'Total Sales',
    sales: 'Sales',
    daily: 'Daily',
    add_admin: 'Add Admin',
    name: 'Name',
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email',
    address: 'Address',
    password: 'Password',
    phone: 'Phone',
    country_code: 'Country Code',
    permissions: 'Permissions',
    status: 'STATUS',
    rule: 'Rule',
    full_permissions: 'Full Permissions',
    some_permissions: 'Some Permissions',
    no_permissions: 'No Permissions',
    read_only: 'Read Only',
    edit: 'Edit',
    delete: 'Delete',
    search_by: 'Search by',
    profile: "Profile",
    logout: "LogOut",
    cancel: "Cancel",
    submit: "Submit",
    add: "Add",
    select: "Select",
    deleted: "Deleted",
    next: 'Next',
    previous: 'Previous',
    go_to_page: 'Go to Page',
    page: 'Page',
    of: 'of',
    add_categories: 'Add Categories',
    active: 'Active',
    not_active: 'Not Active',
    image: 'Image',
    deactive: 'Deactive',
    english: 'English',
    arabic: 'Arabic',
    category_image: 'Category Image',
    close: ' Close',
    price: 'Price',
    in_stock: 'In Stock',
    english_title: 'English Title',
    english_name: 'English Name',
    arabic_title: 'Arabic Title',
    arabic_name: 'Arabic Name',
    english_description: 'English Description',
    arabic_description: 'Arabic Description',
    code: 'Code',
    cost: 'Cost',
    quantity: 'Quantity',
    weight: 'Weight',
    offer_price: 'Offer Price',
    best_seller: 'Best Seller',
    new_in: 'New In',
    offer: 'Offer',
    images: 'Images',
    create: 'Create',
    types_by_english: 'Types by English',
    types_by_arabic: 'Types by Arabic',
    add_new_value: 'Add New Value',
    chosse: "Chosse",
    has_amount: "Has Amount",
    amount: "Amount",
    available_amount: "Available Amount",
    payment_method: "Payment Method",
    total_price: "Total Price",
    sub_price: "Sub Price",
    total: "Total",
    customer_name: "Customer Name",
    exist_user: 'Exist User',
    new_user: 'New User',
    search: "Search",
    order_day: 'Order Day',
    from: "From",
    to: "To",
    coupon: "Coupon",
    apply: "Apply",
    enter: "Enter",
    add_new_product: "Add New Product",
    date_range: "Date Range",
    export: "Export",
    visa: 'Visa',
    cash: 'Cash',
    type: "Type",
    end_date: "End Date",
    start_date: "Start Date",
    max_usage: "Max Usage",
    count_usage: "Count Usage",
    percentage: "Percentage",
    fixed: "Fixed",
    net_profit: "Net Profit",
    or: "Or",
    serial_number: 'Serial Number',
    serial_image: 'Serial Image',
    title: 'Title',
    message: 'Message',
    send: 'Send',
    delete_message: 'Kindly note that this action can`t be undone and the details will get deleted.',
    welcome_back: 'Welcome Back',
    sign_text: 'Sign in by entering information below',
    text: 'Very Good Works are Waiting for you',
    sign_in: 'Sign In',
    specific_block: "Specific Block",
    capacity: 'Capacity',
    interval: 'Interval',
    open: 'Open',
    rate: 'Rate',
    country_name: 'Country Name',
    country: 'Country',
    banner: 'Banner',
    link: 'Link',
    ad: 'Ad',
    add_new_screen: 'Add New Screen',
    website_title: 'Website Title',
    website_logo: 'Website Logo',
    mobile_logo: 'Mobile Logo',
    dashboard_logo: 'Dashboard Logo',
    color: 'Color',
    label_color: 'Label Color',
    possible_delivery: 'Possible Delivery',
    cash_in_delivery_fees: 'Cash in Delivery Fees',
    shipping_fee: "Shipping Fee",
    price_per_kg: 'Price per Kg',
    type_of_area: 'Type of Area',
    all_area: 'All Area',
    per_area: 'Per Area',
    delivery_fees: 'Delivery Fees',
    facebook: 'Facebook',
    snapchat: 'Snapchat',
    instagram: 'Instagram',
    whatsapp: 'Whatsapp',
    twitter: 'Twitter',
    linked_in: 'Linked In',
    telegram: 'Telegram',
    tiktok: 'Tiktok',
    app_store: 'App Store',
    play_store: 'Play Store',
    call_us: 'Call Us',
    iban: 'Iban',
    about: 'about',
    faqs: 'FAQs',
    privacy: 'Privacy',
    address_name: 'Address Name',
    block: 'Block',
    street: 'Street',
    area: 'Area',
    first: 'First',
    avenue: 'Avenue',
    building_number: 'Building Number',
    floor_number: 'Floor Number',
    office_number: 'Office Number',
    house_number: 'House Number',
    apt_number: 'Apt Number',
    other_instructions: 'Other Instructions',
    delivery_day: 'Delivery Day',
    time: 'Time',
    confirm_added: 'Confirm Added',
    confirm_des: 'You can add another product by change some information',
    add_more_products: 'Add More Same Product',
    reference_id: 'Reference Id',
    invoice_id: 'Invoice Id',
    post_date: 'Post Date',
    createdAt: 'CreatedAt',
    order_id: 'Order Id',
    static_pages: 'Static Pages',
    field_required: 'This Field is required',
    ordered_successfully: 'Ordered Successfully',
    ordered_failed: 'Ordered Failed',
    failed_msg: 'The payment process is not complete',
    back_home: 'Back to Home',
    knet: 'Knet'
}
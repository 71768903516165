export const Arabic = {
    dashboard: 'الصفحه الرئيسيه',
    admins: "المدراء",
    admin: "المشرف",
    rules: "صلاحيات",
    users: "المستخدمين",
    categories: "التصنيفات",
    category: "التصنيف",
    sub_categories: "التصنيفات الفرعيه",
    sub_category: "التصنيف الفرعي",
    brands: "العلامات التجارية",
    variant: "اضافات",
    dynamic_variant: 'اضافات بسعر',
    products: 'المنتجات',
    product: 'المنتج',
    digital_products:'المنتجات الرقمية',
    orders: 'طلبات',
    order: 'طلبات',
    reports: 'التقارير',
    notification: 'الاشعارات',
    promo_codes: 'اكواد الخصم',
    promo_code: 'اكواد الخصم',
    time_slot: 'اوقات العمل',
    banners: 'لافتات',
    ad_screen: 'اعلانات',
    control: 'تحكم',
    setting: 'اعدادات',
    delivery: 'توصيل',
    social_media: 'وسائل التواصل',
    currency: 'العمله',
    payment: 'الدفع',
    pages: 'الصفحات',
    total_users: 'إجمالي المستخدمين',
    total_categories: 'إجمالي التصنيفات',
    total_sub_categories: 'إجمالي التصنيفات',
    total_brands: 'إجمالي العلامات التجارية',
    total_admins: 'إجمالي المدراء',
    total_orders: 'إجمالي الطلبات',
    on_the_way: 'في الطريق',
    canceled: 'ألغيت',
    delivered: 'تم التوصيل',
    shipped: "تم الشحن",
    process: "يتم المعالجه",
    ordered: "تم الطلب",
    total_sales: 'إجمالي المبيعات',
    sales: 'المبيعات',
    daily: 'يومي',
    add_admin: 'إضافة المشرف',
    name: 'الإسم',
    first_name: 'الإسم الاول',
    last_name: 'الإسم الآخير',
    email: 'بريد إلكتروني',
    address: 'العنوان',
    phone: 'رقم التليفون',
    password: 'الرقم السري',
    country_code: 'كود الدوله',
    permissions: 'الأذونات',
    status: 'حالة',
    rule: 'إذن',
    full_permissions: 'أذونات كاملة',
    some_permissions: 'بعض الأذونات',
    no_permissions: 'لا أذونات',
    read_only: 'قراءه فقط',
    edit: 'تعديل',
    delete: 'مسح',
    search_by: 'البحث عن طريق',
    profile: "الحساب الشخصي",
    logout: "تسجيل الخروج",
    cancel: "إلغاء",
    submit: "إضافه",
    add: "إضافه",
    select: 'اختار',
    deleted: 'تم الحذف',
    next: 'التالي',
    previous: 'السابق',
    go_to_page: 'الذهاب الي صفحه',
    page: 'صفحه',
    of: 'من',
    add_categories: 'إضافة تصنيف',
    active: 'نشط',
    not_active: 'غير نشط',
    image: 'الصوره',
    deactive: 'تعطيل',
    english: 'اللغه الانجليزيه',
    arabic: 'اللغه العربيه',
    category_image: 'صوره التصنيف',
    close: ' اغلاق',
    price: 'السعر',
    in_stock: 'متوفره',
    english_title: 'العنوان باللغة الانجليزية',
    english_name: 'الإسم باللغة الانجليزية',
    arabic_title: 'العنوان باللغة العربية',
    arabic_name: 'الإسم باللغة العربية',
    english_description: 'الوصف باللغة الإنجليزية',
    arabic_description: 'الوصف باللغة العربية',
    code: 'كود المنتج',
    cost: 'التكلفه',
    quantity: 'كمية',
    weight: 'وزن',
    offer_price: 'سعر العرض',
    best_seller: 'الأكثر مبيعًا',
    new_in: 'الجديد في',
    offer: 'يعرض',
    images: 'الصور',
    create: 'إنشاء',
    types_by_english: 'أنواع باللغة الإنجليزية',
    types_by_arabic: 'أنواع باللغة العربية',
    add_new_value: 'إضافة قيمة جديدة',
    chosse: 'اختيار',
    has_amount: 'لديه كميه',
    amount: 'الكميه',
    available_amount: 'الكميه المتاحه',
    payment_method: "طريقه الدفع",
    total_price: "السعر الكلي",
    sub_price: "السعر الجزئي",
    total: "الكلي",
    customer_name: "إسم العميل",
    exist_user: 'عميل موجود',
    new_user: 'عميل جديد',
    search: "بحث",
    order_day: 'يوم التوصيل',
    from: "من",
    to: "إلي",
    coupon: "كود الخصم",
    apply: "تطبيق",
    enter: "أدخل",
    add_new_product: 'إضافه منتج جديد',
    date_range: "المده",
    export: "تصدير",
    visa: 'فيزا',
    cash: 'كاش',
    type: 'النوع',
    end_date: "تاريخ الإنتهاء",
    start_date: "تاريخ البدأ",
    max_usage: "الاستخدام الأقصى",
    count_usage: "عدد الإستخدام",
    percentage: "نسبة مئوية",
    fixed: "نسبة ثابته",
    net_profit: "صافي الربح",
    or: "أو",
    serial_number: 'الرقم التسلسلي',
    serial_image: 'الصورة التسلسلية',
    title: 'العنوان',
    message: 'الرساله',
    send: 'إرسال',
    delete_message: 'يرجى ملاحظة أنه لا يمكن التراجع عن هذا الإجراء وسيتم حذف التفاصيل.',
    welcome_back: 'مرحبا بعودتك',
    sign_text: 'قم بتسجيل الدخول عن طريق إدخال المعلومات أدناه',
    text: 'أعمال جيدة جدا في انتظاركم',
    sign_in: 'تسجيل الدخول',
    specific_block: "يوم العطله",
    capacity: 'سعه',
    interval: 'فاصلة',
    open: 'فتح',
    rate: 'معدل',
    country_name: 'اسم الدولة',
    country: 'الدولة',
    banner: 'لافته',
    link: 'رابط',
    ad: 'إعلان',
    add_new_screen: 'إضافه شاشه أخري',
    website_title: 'عنوان موقع الويب',
    website_logo: 'شعار موقع الويب',
    mobile_logo: 'شعار الجوال',
    dashboard_logo: 'شعار لوحة المعلومات',
    color: 'اللون',
    label_color: 'لون النصوص',
    possible_delivery: 'امكانيه التوصيل',
    cash_in_delivery_fees: 'رسوم تسليم النقد',
    shipping_fee: "رسوم الشحن",
    price_per_kg: 'السعر للكيلو جرام',
    type_of_area: 'نوع المنطقة',
    all_area: 'لكل المناطق',
    per_area: 'للمنطقة',
    delivery_fees: 'رسوم التوصيل',
    facebook: 'فيسبوك',
    snapchat: 'سناب شات',
    instagram: 'إنستغرام',
    whatsapp: 'واتس اب',
    twitter: 'تويتر',
    linked_in: 'لينكد إن',
    telegram: 'تيليجرام',
    tiktok: 'تيك توك',
    app_store: 'متجل أبل',
    play_store: 'متجر جوجل',
    call_us: 'اتصل بنا',
    iban: 'رقم الآيبان',
    about: 'حول',
    faqs: 'الأسئلة الشائعة',
    privacy: 'الخصوصية',
    address_name: 'العنوان',
    block: 'كتلة',
    street: 'الشارع',
    area: 'المنطقة',
    first: 'أولا',
    avenue: 'الجادة',
    building_number: 'رقم المبنى',
    floor_number: 'رقم الطابق',
    office_number: 'رقم المكتب',
    house_number: 'رقم المنزل',
    apt_number: 'رقم الشقة',
    other_instructions: 'تعليمات أخرى',
    delivery_day: 'يوم التوصيل',
    time: 'الوقت',
    confirm_added: 'تأكيد الإضافة',
    confirm_des: 'يمكنك إضافة منتج آخر عن طريق تغيير بعض المعلومات',
    add_more_products: 'أضف المزيد من نفس المنتج',
    reference_id: 'الرقم المرجعي',
    invoice_id: 'رقم الفاتورة',
    post_date: 'التاريخ',
    createdAt: 'أنشئت في',
    order_id: 'رقم الطلب',
    static_pages: 'الصفحات الثابته',
    field_required: 'هذا الحقل مطلوب',
    ordered_successfully: 'تم الطلب بنجاح',
    ordered_failed: 'فشل الطلب',
    failed_msg: 'عمليه الدفع لم تكتمل',
    back_home: 'العودة إلى الصفحة الرئيسية',
    knet: 'كي نت'
}
export const MenuList = [
    //Dashboard
    {   
        title:'Dashboard',
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-381-networking" />,
        to: 'dashboard',
        text: 'dashboard',
    },
    //Admins
    {   
        title:'Admins',
        classsChange: 'mm-collapse',
        iconStyle: <i className='la la-user-shield'></i>,
        to: 'admins',
        text: 'admins',
    },
    //Rules
    {   
        title:'Rules',
        classsChange: 'mm-collapse',
        iconStyle: <i className='la la-shield'></i>,
        to: 'rules',
        text: 'rules',
    },
    //Users
    {   
        title:'Users',
        classsChange: 'mm-collapse',
        iconStyle: <i className='la la-users'></i>,
        to: 'users',
        text: 'users',
    },
    //Categories
    {   
        title:'Categories',
        classsChange: 'mm-collapse',
        iconStyle: <i className='la la-cubes'></i>,
        to: 'categories',
        text: 'categories',
    },
    //SubCategories
    {   
        title:'SubCategories',
        classsChange: 'mm-collapse',
        iconStyle: <i className='la la-cube'></i>,
        to: 'sub-categories',
        text: 'sub_categories',
    },
    //Brands
    {   
        title:'Brands',
        classsChange: 'mm-collapse',
        iconStyle: <i className='la la-file-text'></i>,
        to: 'brands',
        text: 'brands',
    },
    //Variant
    {   
        title:'Variant',
        classsChange: 'mm-collapse',
        iconStyle: <i className="la la-slack" />,
        to: 'variant',
        text: 'variant',
    },
    //DynamicVariant
    {   
        title:'Dynamic Variant',
        classsChange: 'mm-collapse',
        iconStyle: <i className="la la-simplybuilt" />,
        to: 'dynamic-variant',
        text: 'dynamic_variant',
    },
    //Products
    {   
        title:'Products',
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-381-notepad" />,
        to: 'products',
        text: 'products',
    },
    //Digital Products
    {   
        title:'Digital Products',
        classsChange: 'mm-collapse',
        iconStyle: <i className="la la-qrcode"></i>,
        to: 'digital-products',
        text: 'digital_products',
    },
    //Orders
    {   
        title:'Orders',
        classsChange: 'mm-collapse',
        iconStyle: <i className='la la-truck'></i>,
        to: 'orders',
        text: 'orders',
    },
    //Reports
    {   
        title:'Reports',
        classsChange: 'mm-collapse',
        iconStyle: <i className='la la-pie-chart'></i> ,
        to: 'reports',
        text: 'reports',
    },
    // Notification
    {   
        title:'Notification',
        classsChange: 'mm-collapse',
        iconStyle: <i className='la la-bell'></i>,
        to: 'notification',
        text: 'notification',
    },
     //Promo Codes
     {   
        title:'Promo Codes',
        classsChange: 'mm-collapse',
        iconStyle: <i className='la la-dollar'></i>,
        to: 'promo-codes',
        text: 'promo_codes',
    },
     //Time Slot
     {   
        title:'Time Slot',
        classsChange: 'mm-collapse',
        iconStyle: <i className='la la-clock'></i>,
        to: 'time-slot',
        text: 'time_slot',
    },
    //Banners
    {   
        title:'Banners',
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-381-television" />,
        to: 'banners',
        text: 'banners',
    },
    //Ad Screen
    {   
        title:'Ad Screen',
        classsChange: 'mm-collapse',
        iconStyle: <i className="la la-thumb-tack" />,
        to: 'ad-screen',
        text: 'ad_screen',
    },
    //Control
    {   
        title:'Control',
        classsChange: 'mm-collapse',
        iconStyle: <i className="la la-wrench" />,
        to: 'control',
        text: 'control',
    },
   
    //Setting
    {
        title:'Setting',
        text:'setting',
        classsChange: 'mm-collapse',
        iconStyle: <i className='la la-cog'></i>,
        content : [
            {
                title:'Delivery',
                text:'delivery',
                to: 'delivery',
                rule: 'delivery',
            },
            {
                title:'Social Media',
                text:'social_media',
                to: 'social',
                rule: 'social_media',
            },
            {
                title:'Currency',
                text: 'currency',
                to: 'currency',
                rule: 'currency',
            },
            {
                title:'Payment',
                text: 'payment',
                to: 'payment',
                rule: 'payment',
            },
            {
                title:'Pages',
                text: 'pages',
                to: 'pages',
                rule: 'static_pages',
            },
        ]
    },
    
]